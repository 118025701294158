import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image";
import {css} from "@emotion/react"
import styled from "@emotion/styled"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Heading from "../../components/typography/heading";
import SpanBold from "../../components/typography/SpanBold";
import ContentWrapper from "../../components/wrappers/contentWrapper";


const HeroImage = styled.div`
  margin: 0 auto;
  width: 100%;
  max-height: 600px;
  overflow: hidden;
`

const ServicesIndex = () => {
  return (
    <Layout>
      <Seo
        title="General Bookkeeping Services for the greater vancouver area"
        description="Bookkeeping services, including Quickbooks Pro Advisor, General Bookkeeping, Accounts Payable & Accounts receivable"
        canonical="https://labellebookkeeping.com/services/"
      />

      <HeroImage>

        <StaticImage
          src="../../images/hero/hero_splash_general_bookkeeping.jpg"
          alt="quickbooks pro advisor hero image"
          objectPosition="50% 50%"
        />
      </HeroImage>

      <ContentWrapper>
        <Heading>General Bookkeeping Services</Heading>

        <p>
          I provide general bookkeeping services to sole proprietorship's and business owners who want to simplify their financial records into an easy-to-manage workflow. I have my Bookkeeping Certification for Small Businesses and years of experience behind me working as a general bookkeeper in various industries.
        </p>
        <p>
          I'm available for small and large projects alike. My experience includes traditional brick-and-mortar businesses, such as hospitality and construction, and large corporations, such as the financial and procurement sector of the RCMP, where I handled large amounts of data.
        </p>

        <div css={css` margin-top: 2em;
          margin-bottom: 3rem;
          @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            justify-content: center;
          }`}>
          <div>
            <p>
              <SpanBold>General tasks include:</SpanBold>
            </p>
            <p>
              <ul>
                <li>Record keeping and categorizing financial transactions</li>
                <li>Bank and Credit Card Reconciliations</li>
                <li>Monthly, Annual or quarterly tax remittance</li>
                <li>
                  Monthly financial statements and personalized advice based on the
                  analytics of your business reports
                </li>
              </ul>
            </p>
          </div>
          <div>
            <p>
              <SpanBold>Specific roles include:</SpanBold>
            </p>
            <p>
              <ul>
                <li>
                  General Bookkeeping
                </li>
                <li>
                  <Link to="/services/accounts-payable/"
                        title="Accounts Payable services">
                    Accounts Payable
                  </Link>
                </li>
                <li>
                  <Link to="/services/accounts-receivable/"
                        title="Accounts Receivable Services">
                    Accounts Receivable
                  </Link>
                </li>
                <li>
                  <Link to="/services/payroll-services/"
                        title="Payroll Services">
                    Accounts Receivable
                  </Link>
                </li>
                <li>
                  <Link to="/services/quickbooks-online-proadvisor/" title="Quickbooks Online ProAdvisor">
                    Quickbooks Online ProAdvisor
                  </Link>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  )
}

export default ServicesIndex
